<template>
  <div>
    <v-tabs
      height="56"
      v-model="tab"
      :elevation="10"
      :style="{ width: '100%', position: 'absolute', paddingLeft: isMiniMenu ? '75px' : '190px' }"

    >
      <v-tab
        v-for="(item, index) in menuItems"
        :key="index"
        :disabled="item.disabled"
        :color="!item.disabled ? '#ff9800' : '#5d5d5d'"
        :to="item.link"
      >
        <v-icon v-if="item.icon" class="mr-2">{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <div style="display: flex;">
      <SideBar :isMini="isMiniMenu" @update:isMini="isMiniMenu = $event" ></SideBar>
      <div style="flex: 1;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from '../components/curves/SideBar.vue'

export default {
  components: {
    SideBar,
  },
  data () {
    return {
      tab: undefined,
      menuItems: [
        { title: 'Vertices', icon: 'mdi-chart-line', link: '/grid/home-curves/vertices'},
        { title: 'Curves', icon: 'mdi-chart-bell-curve', link: '/grid/home-curves/curves'},
      ],
      isMiniMenu: false
    }
  },
}
</script>

<style scoped>
</style>