import VueRouter from "vue-router";
import HomeView from "./../views/HomeView.vue";
import AuthenticatedLayout from "./../layouts/AuthenticatedLayout.vue";
import LandingView from "./../views/LandingView.vue";
import LoginView from "./../views/LoginView.vue";
import NotFoundView from './../views/NotFoundView.vue';
import SparklineView from './../views/SparklineView.vue';
import ReportsView from './../views/ReportsView.vue';
import ExposureView from "./../views/ExposureView.vue";
import CashFlowView from "./../views/CashFlowView.vue";
import ConsoleView from './../views/ConsoleView.vue';
import CurvesView from './../views/CurvesView.vue';
import CurvesCharts from './../components/curves/CurvesCharts.vue'
import VerticesCharts from './../components/curves/VerticesCharts.vue'
import { useAuthStore } from "./../store/index"

const auth = Number(process.env.VUE_APP_AUTH)

const routes = [
  { 
    path: "/", 
    name: "landing",
    // component: () => import("./../views/LandingView.vue"),
    component: LandingView,
    meta: {
      public: true
    },
  },
  {
    path: "/login",
    name: "login",
    // component: () => import("./../views/LoginView.vue"),
    component: LoginView,
    meta: {
      public: true
    },
  },
  {
    path: "/grid",
    component: AuthenticatedLayout,
    meta: {
      public: false
    },
    children: [
      {
        path: "trading",
        name: "trading",
        component: HomeView,
        // component: () => import("./../views/HomeView.vue"),
      },
      {
        path: "fxmarket",
        name: "fxmarket",
        component: SparklineView,
        // redirect: "trading-view/AUD/USD"
        // component: () => import("./../views/SparklineView.vue"),
      },
      {
        path: "home-curves",
        name: "home-curves",
        component: CurvesView,
        children: [
          {
            path: "vertices",
            name: "vertices",
            component: VerticesCharts,
          },
          {
            path: "curves",
            name: "curves",
            component: CurvesCharts,
          },
        ]
      },
      {
        path: "reports",
        name: "reports",
        component: ReportsView,
        // redirect: "trading-view/AUD/USD"
        // component: () => import("./../views/SparklineView.vue"),
      },
      {
        path: "exposure",
        name: "exposure",
        component: ExposureView,
        // redirect: "trading-view/AUD/USD"
        // component: () => import("./../views/ExposureView.vue"),
      },
      {
        path: "cashflow",
        name: "cashflow",
        component: CashFlowView,
        // redirect: "trading-view/AUD/USD"
        // component: () => import("./../views/CashFlowView.vue"),
      },
      {
        path: "console",
        name: "console",
        component: ConsoleView,
        // redirect: "trading-view/AUD/USD"
        // component: () => import("./../views/ConsoleView.vue"),
      },
      // {
      //   path: "trading-view/:fChartId/:sChartId",
      //   name: "trading-view",
      //   component: SparklineView,
      //   // component: () => import("./../views/SparklineView.vue"),
      // },
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundView
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

function handleRedirect (to, next, isPublicRoute, authenticated) {
  const onlyLoggedOutRoute = to.matched.some(record => record.meta.public)
  // no-logged try to access private route
  if (!isPublicRoute && !authenticated) {
    return next("/login")
  }
  // logged try to access public route
  if (authenticated && onlyLoggedOutRoute) {
    return next("/grid/fxmarket")
  }
  return next()
}

router.beforeEach((to, from, next) => {
  if (auth === 0) {
    return next()
  }
  const authStore = useAuthStore()
  let authenticated = authStore.isAuthenticated
  const isPublicRoute = to.matched.some(record => record.meta.public)
  // validation for user refresh page (F5) cenario
  if (authenticated === undefined) {
    authStore.loadAuthenticatedAndUserIdStateFromLocalStorage()
    authenticated = authStore.isAuthenticated
    if (!isPublicRoute) {
      if (authenticated) {
        return handleRedirect(to, next, isPublicRoute, authenticated)
      }
      return next("/login")
    }
  }
  return handleRedirect(to, next, isPublicRoute, authenticated)
})

// router.afterEach((to, from) => {
  // store.commit("setCurrentRouteName", to.name)
  // store.commit("setToolbarTitle", to.meta.pageTitle)
  // store.commit("setPreviousPage", from.path)
  // store.commit("setMaxHeightCurrentRoute", to.meta.maxHeight)
  // store.commit("setBackgroundColor", to.meta.backgroundColor)
  // resize.onResize()
// })

export default router
