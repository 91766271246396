<template>
  <div>
    <v-navigation-drawer
      :mini-variant="isMini"
      mini-variant-width="75"
      width="190"
      permanent
      color="base"
    >
      <v-list :rounded="!isMini">
        <v-list-item class="px-2" style="min-height: 61.98px; max-height: 61.98px;">
          <v-list-item-content v-if="!isMini">
            <v-list-item-title class="font-weight-bold text-h6">Controls</v-list-item-title>
          </v-list-item-content>

          <v-btn icon color="accent" @click="$emit('update:isMini', !isMini)">
            <v-icon>
              {{ isMini ? "mdi-chevron-right" : "mdi-chevron-left" }}
            </v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <v-list>
        <v-list-item
          v-if="!isMini"
          class="d-flex flex-column"
          style="min-height: 0px"
        >
          <v-list-item-content class="d-flex justify-center font-weight-bold mb-n7">
            <v-list-item-title class="text-center">{{ displayedCharts.di1OrPre }}</v-list-item-title>
          </v-list-item-content>

          <v-radio-group v-model="selectedOptionDI1">
            <v-radio label="No override" value="noOverride" class="disabled" disabled></v-radio>
            <v-radio label="Adjust Px" value="adjustPx" class="disabled" disabled></v-radio>
            <v-radio label="Last Px" value="lastPx" class="disabled" disabled></v-radio>
            <v-radio label="Close Px (D-1)" value="closePx" class="disabled" disabled></v-radio>
            <v-radio label="Interpolate" value="interpolate" class="disabled" disabled></v-radio>
            <v-radio label="Excel Add-in" value="excelAdd" ></v-radio>
          </v-radio-group>
          <v-btn disabled class="mt-n4"> Submit </v-btn>
        </v-list-item>

        <v-list-item
          v-if="!isMini"
          class="d-flex flex-column mt-2"
          style="min-height: 0px"
        >
          <v-list-item-content class="d-flex justify-center font-weight-bold mb-n7">
            <v-list-item-title class="text-center">{{ displayedCharts.frcOrcpnDol }}</v-list-item-title>
          </v-list-item-content>

          <v-radio-group v-model="selectedOptionFRC">
            <v-radio label="No override" value="noOverride" class="disabled" disabled></v-radio>
            <v-radio label="Adjust Px" value="adjustPx" class="disabled" disabled></v-radio>
            <v-radio label="Last Px" value="lastPx" class="disabled" disabled></v-radio>
            <v-radio label="Close Px (D-1)" value="closePx" class="disabled" disabled></v-radio>
            <v-radio label="Interpolate" value="interpolate" class="disabled" disabled></v-radio>
            <v-radio label="Excel Add-in" value="excelAdd" ></v-radio>
          </v-radio-group>
          <v-btn disabled class="mt-n4"> Submit </v-btn>
        </v-list-item>

        <v-divider v-if="!isMini" class="mt-2"></v-divider>

        <v-form
          v-if="!isMini"
          fast-fail
          @submit.prevent
          class="mx-auto"
          style="width: 160px"
        >
          <v-text-field
            v-model="cdi"
            :rules="onlyNumberRules"
            label="CDI"
            @input="handleInput"
          ></v-text-field>

          <v-text-field
            v-model="wdof25"
            :rules="onlyNumberRules"
            label="WDOF25"
            @input="handleInput"
          ></v-text-field>
          
          <v-text-field
            v-model="wdog25"
            :rules="onlyNumberRules"
            label="WDOG25"
            @input="handleInput"
          ></v-text-field>

          <v-text-field
            v-model="dr1"
            :rules="onlyNumberRules"
            label="DR1"
            @input="handleInput"
          ></v-text-field>
          <!-- <v-btn class="" type="submit" @click="setForms" block>Submit</v-btn> -->
        </v-form>

        <div v-if="isMini" class="d-flex flex-column align-center text-subtitle-2">
          <div v-for="(value, key) in compactData" :key="key" class="d-flex flex-column align-center mb-1">
            <div class="font-weight-black primary--text">
              <span>{{ key }}:</span>
            </div>
            <div class="font-weight-black">
              <span>{{ value }}</span>
            </div>
          </div>
        </div>
      </v-list>

      <v-divider v-if="true"></v-divider>
      
      <div>
        <div
          class="d-flex flex-column ml-4 mr-2"
          style="font-size: 14px;"
        >
          <div v-if="!isMini" class="d-flex flex-column mt-2">
            <div class="mb-1">
              <span class="font-weight-bold">Last fetch: </span>
              <span v-if="lastUpdateState"> {{ new Date (lastUpdateState) | formatTime }} </span>
              <span v-else> {{ new Date (this.now) | formatTime }} </span>
            </div>

            <div
              class="d-flex justify-center mb-1"
              style="color: white; background-color: rgba(0, 0, 0, 0.8); gap: 5px"
            >
              <span> Reload in </span>
              <span> {{ countdown }} </span> secs
            </div>

            <div class="d-flex">
              <span class="font-weight-bold">OctaX PRO:</span>
              <span 
                class="octaXsync ml-2"
                :class="[isSynced ? 'syncON' : 'syncOFF']"
              > SYNC </span>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia"
import { useTableStore } from "./../../store/curves"

export default {
  name: 'SideBar',
  data () {
    return {
      cdi: '',
      dr1: '',
      wdof25: '',
      wdog25: '',
      editTimeout: undefined,
      interval: null,
      isEditing: false,
      isSynced: false,
      now: Date.now(),
      onlyNumberRules: [
        v => !isNaN(v) || 'Only numbers are allowed',
      ],
      selectedOptionDI1: "excelAdd",
      selectedOptionFRC: "excelAdd",
      snackbarSuccess: false,
    }
  },
  props: {
    isMini: Boolean
  },
  filters: {
    formatTime(date) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.now = Date.now()
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
    clearTimeout(this.editTimeout)
  },
  computed: {
    ...mapState(useTableStore, [
      "lastUpdate",
      "displayedCharts"
    ]),
    ...mapWritableState(useTableStore, {
      lastUpdateState: "lastUpdate",
    }),
    compactData() {
      return {
        CDI: this.cdi,
        WDOF25: this.wdof25,
        WDOG25: this.wdog25,
        DR1: this.dr1
      }
    },
    countdown() {
      if (!this.lastUpdate) return 5
      const elapsedTime = Math.floor((this.now - new Date(this.lastUpdate).getTime()) / 1000)
      return Math.max(4 - elapsedTime, 0)
    },
    // now () {
    //   return new Date()
    // },
  },
  methods: {
    handleInput () {
      this.isEditing = true
      this.resetEditTimeout()
    },
    resetEditTimeout() {
      if (this.editTimeout) {
        clearTimeout(this.editTimeout)
      }
      this.editTimeout = setTimeout(() => {
        this.isEditing = false
      }, 10000)
    },
    // startCountdown() {
    //   if (this.countdownInterval) {
    //     clearInterval(this.countdownInterval)
    //   }

    //   let seconds = 5
    //   this.countdown = seconds

    //   this.countdownInterval = setInterval(() => {
    //     seconds--
    //     this.countdown = seconds

    //     if (seconds <= 0) {
    //       clearInterval(this.countdownInterval)
    //     }
    //   }, 1000)
    // },
  }
}
</script>

<style scoped>

@font-face {
  font-family: AvenirNextM-Regular;
  src: url("~@/assets/fonts/AvenirNextM-Regular.ttf");
}
::v-deep div.v-radio.disabled.v-radio--is-disabled.theme--dark > div > i {
  color: #303030;
}

.octaXsync {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  text-align: center;
  font-size: 10px;
  font-family: Arial, sans-serif;
}

.syncON {
  color: #00ff00;
  text-shadow: 0 0 8px #00ff00;
}
.syncOFF {
  color: #ff0000;
  text-shadow: 0 0 8px #ff0000;
}

</style>