<template>
  <div 
    style="display: contents;"
  >
    <v-chart class="chart" autoresize :option="options" />
  </div>
</template>

<script>
import { use } from "echarts/core"
import { PieChart } from "echarts/charts"
import { TooltipComponent, LegendComponent } from "echarts/components"
import { CanvasRenderer } from "echarts/renderers"
import { useConsoleApiStore } from "./../../store/console.js"
import { mapState, mapActions } from "pinia"

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

import VChart from "vue-echarts";

export default {
  components: {
    VChart,
  },
  mounted () {
    this.fetchAlerts();
  },
  methods: {
    ...mapActions(useConsoleApiStore, [
      "fetchAlerts",
    ]),
  },
  computed: {
    ...mapState(useConsoleApiStore, [
      "alerts",
    ]),
    options () {
      return {
        darkMode: true,
        color: [
          "#F44336",
          "#FFB300",
          "#00C853"
        ],
        // tooltip: {
        //   trigger: "item"
        // },
        legend: {
          orient: "vertical",
          top: "center",
          left: "right",
          align: "left",
          textStyle: {
            color: "#fff",
            fontWeight: 300,
            fontSize: 16
          },
          itemGap: 30,
          selected: {
            "0 CRITICAL": false,
            "0 WARNING": false,
            "0 NO ALERTS": false,
          },
          selectedMode: false
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["50%", "60%"],
            label: {
              show: false,
            },
            // labelLine: {
            //   show: false
            // },
            data: [
              {
                value: this.alerts.critical || 0,
                name: `${this.alerts.critical || 0} ${this.$t('console.donut.critical').toUpperCase()}`,
              },
              {
                value: this.alerts.warning || 0,
                name: `${this.alerts.warning || 0} ${this.$t('console.donut.warning').toUpperCase()}`,
              },
              {
                value: this.alerts['no alerts'] || 0,
                name: `${this.alerts['no alerts'] || 0} ${this.$t('console.donut.noAlerts').toUpperCase()}`,
              },
            ]
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
  margin-left: -120px;
  width: 595px;
}
</style>
