<template>
  <div>
    <v-row no-gutters class="mb-8 px-8">
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>

      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        class="pt-8 d-flex flex-column"
      >
        <v-row class="d-flex pb-8">
          <v-col class="d-flex justify-center text-h3 font-weight-light text-center">
            {{ $t('title.exposure') }}
          </v-col>
          <v-col v-if="false" cols="2" class="font-weight-light">
            <!-- {{ now }} -->
          </v-col>
        </v-row>

        <v-card outlined style="background-color: #121212;">
          <v-card-title class="d-flex">
            <span class="font-weight-bold">
              {{ $t('exposure.exposureUSD.title') }}
            </span>

            <v-spacer></v-spacer>

            <v-btn
              elevation="0"
              tile
              color="primary"
              :disabled="!isExposicaoCambialModified"
              @click="saveChanges('itemsExposicaoCambial')"
            >
            {{ $t('exposure.button') }}
            </v-btn>
          </v-card-title>

          <div style="margin-left: 70px; margin-right: 70px;">
            <v-simple-table class="no-hover" style="background-color: #121212;">
              <tbody>
                <tr
                  v-for="(item, rowIndex) in itemsExposicaoCambial"
                    :key="rowIndex" 
                    :class="{'exclusive-cell': rowIndex === 3}"
                >
                  <td :class="{'font-weight-black': rowIndex >= 3}" style="width: 230px;">
                    <template v-if="rowIndex === 0">
                      <v-select
                        v-model="selectedPeriod"
                        :items="periodOptions"
                        dense
                        class="mb-n4 centered-input"
                      />
                    </template>
                    {{ headersExposure[rowIndex] }}
                  </td>

                  <td
                    v-for="(value, colIndex) in (rowIndex === 0 ? dynamicPeriods : item.values)"
                    :key="colIndex"
                    :class="{
                      'font-weight-black': rowIndex === 0,
                    }"
                    class="text-center"
                  >
                    <v-text-field
                      v-if="[1, 2].includes(rowIndex)"
                      v-model="item.values[colIndex]"
                      :disabled="false"
                      dense
                      class="mb-n4 centered-input remove-spin"
                      style="text-align: center;"
                      @input="updateItemsExposicaoCambial(colIndex, itemsExposicaoCambial[colIndex].values)"
                      type="number"
                    >
                    </v-text-field>

                    <template v-else-if="rowIndex === 4 && colIndex === 1">
                      <span>{{ exposureRiskItems[0] }}</span>
                    </template>

                    <template v-else-if="rowIndex === 5 && colIndex === 1">
                      <span>{{ exposureRiskItems[1] }}</span>
                    </template>
                    
                    <template v-else>
                      {{ value }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>

        <v-card outlined class="mt-10 mb-4" style="background-color: #121212;">
          <v-card-title class="d-flex">
            <span class="font-weight-bold">
              {{ $t('exposure.hedging.title') }}
            </span>

            <v-spacer></v-spacer>

            <v-btn
              elevation="0"
              tile
              color="primary"
              :disabled="!isHedgingModified"
              @click="saveChanges('itemsHedging')"
            >
            {{ $t('exposure.button') }}
            </v-btn>
          </v-card-title>

          <div style="margin-left: 70px; margin-right: 70px">
            <v-simple-table class="no-hover" style="background-color: #121212;">
              <tbody>
                <tr v-for="(item, rowIndex) in itemsHedging" :key="rowIndex" 
                    :class="{'exclusive-cell': rowIndex === 4}">
                  <td :class="{'font-weight-bold': rowIndex >= 4}" style="width: 350px;">
                    {{ headersHedging[rowIndex] }}
                  </td>
                  
                  <td
                    v-for="(value, colIndex) in (rowIndex === 1 ? dynamicPeriods : item.values)"
                    :key="colIndex"
                    :class="{
                      'font-weight-bold': rowIndex === 1,
                    }"
                    class="text-center"
                  >

                    <v-text-field
                      v-if="[0].includes(rowIndex) && [0].includes(colIndex)"
                      v-model="item.values[colIndex]"
                      :disabled="false"
                      dense
                      class="mb-n4 centered-input remove-spin"
                      style="text-align: center;"
                      @input="updateItemsHedging(colIndex, itemsHedging[colIndex].values)"
                      type="number"
                    >
                    </v-text-field>
                    <template v-else>
                      {{ value }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>

      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia"
import { useTableStore } from "./../store/hedge.js"

export default {
  data () {
    return {
      headersExposure: [
        this.$t(''),
        this.$t('exposure.exposureUSD.receivables'),
        this.$t('exposure.exposureUSD.payments'),
        this.$t('exposure.exposureUSD.netFxExposure'),
        this.$t('exposure.exposureUSD.totalReceivablesVolume'),
        this.$t('exposure.exposureUSD.totalPaymentVolume'),
        this.$t('exposure.exposureUSD.totalFxExposure'),
        this.$t('exposure.exposureUSD.VaR')
      ],
      exposureRiskItems: [
        this.$t('exposure.exposureUSD.downsideRisk'),
        this.$t('exposure.exposureUSD.upsideRisk')
      ],
      headersHedging: [
        this.$t('exposure.hedging.maximumHedgeRatio'),
        this.$t('exposure.hedging.term.title'),
        this.$t('exposure.hedging.ndfSellVolume'),
        this.$t('exposure.hedging.ndfBuyVolume'),
        this.$t('exposure.hedging.netExposure'),
        this.$t('exposure.hedging.totalDerivativesHedge'),
        this.$t('exposure.hedging.totalForeignExchangeExposure'),
        this.$t('exposure.hedging.VaR')
      ],
      itemsExposicaoCambial: [],
      itemsHedging: [],
      isExposicaoCambialModified: false,
      isHedgingModified: false,
      selectedPeriod: "Monthly",
      periodOptions: ["Monthly", "Biweekly", "Weekly"],
    }
  },
  computed: {
    ...mapWritableState(useTableStore, {
      itemsAnaliseDeRiscoState: "itemsAnaliseDeRisco",
      itemsFluxoState: "itemsFluxo",
    }),
    ...mapState(useTableStore, [
      "isAnaliseDeRiscoModified",
      "isFluxoModified",
    ]),
    dynamicPeriods() {
      const today = new Date()
      const monthNames = this.$i18n.locale === "en"
        ? [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December",
          ]
        : [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro",
          ]

      const currentMonth = today.getMonth() + 1
      let periods = []

      if (this.selectedPeriod === "Monthly") {
        periods = monthNames.slice(currentMonth, currentMonth + 6)
      } else if (this.selectedPeriod === "Biweekly") {
        for (let i = 0; i < 6; i++) {
          const month = monthNames[(currentMonth + Math.floor(i / 2)) % 12]
          periods.push(`Q${i % 2 + 1} / ${month}`)
        }
      } else if (this.selectedPeriod === "Weekly") {
        let weekCount = 1
        let previousMonth = currentMonth

        for (let i = 0; i < 6; i++) {
          const currentMonthIndex = (currentMonth + Math.floor(i / 4)) % 12;

          if (currentMonthIndex !== previousMonth) {
            weekCount = 1
            previousMonth = currentMonthIndex
          }

          const month = monthNames[currentMonthIndex]
          periods.push(`S${weekCount} / ${month}`)
          weekCount++
        }
      }

      return periods
    },
  },
  mounted () {
    this.fetchExposureDataState()
  },
  methods: {
  ...mapActions(useTableStore, {
      fetchExposureData: "fetchExposureData",
      updateExposureData: "updateExposureData"
    }),
    fetchExposureDataState() {
      this.fetchExposureData()
        .then((response) => {
          const data = response.data
          this.itemsExposicaoCambial = data.itemsExposicaoCambial
          this.itemsHedging = data.itemsHedging
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveChanges(parameter) {
      const updatedData = {
        itemsExposicaoCambial: this.itemsExposicaoCambial,
        itemsHedging: this.itemsHedging
      }

      if (parameter === 'itemsExposicaoCambial') {
        this.isExposicaoCambialModified = false
      } else if (parameter === 'itemsHedging') {
        this.isHedgingModified = false
      }

      this.updateExposureData(updatedData)
        .then(() => {
          this.fetchExposureDataState()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateItemsExposicaoCambial(index, value) {
      this.itemsExposicaoCambial[index].values = value
      this.isExposicaoCambialModified = true
    },
    updateItemsHedging(index, value) {
      this.itemsHedging[index].values = value
      this.isHedgingModified = true
    },
  }
}
</script>

<style scoped>
.exclusive-cell {
  background-color: #424242 !important;
}
.centered-input >>> input {
  text-align: center
}
.no-hover tbody tr:hover:not(.exclusive-cell) {
  background-color: unset !important;
}
.remove-spin >>> input[type="number"] {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Outros navegadores */
}
.remove-spin >>> input[type="number"]::-webkit-inner-spin-button,
.remove-spin >>> input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>