<template>
  <div>
    <v-row no-gutters class="mb-8 px-8">
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
  
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        class="pt-8"
      >
        <v-row class="d-flex pb-8">
          <v-col class="d-flex justify-center text-h3 font-weight-light text-center">
          {{ $t('title.reportDashboard') }}
          </v-col>
          <v-col v-if="false" cols="2" class="font-weight-light">
            <!-- {{ now }} -->
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <div
          class="d-flex flex-column flex-sm-row align-center pa-3"
          >
            <div class="mr-0 mr-sm-4">
              <v-btn
                color="primary"
                small
                outlined
                class="mb-2 mb-sm-0"
                @click="expandAll()"
              >
                <span class="white--text">
                  {{ $t('instructions.expandAll') }}
                </span>
              </v-btn>
            </div>
            <div>
              <v-btn
                color="primary"
                small
                outlined
                @click="contractAll()"
              >
                <span class="white--text">
                  {{ $t('instructions.contractAll') }}
                </span>
              </v-btn>
            </div>
          </div>

          <v-col
          class="d-flex flex-column flex-sm-row align-center"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  :label="$t('extras.start')"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                  style="width: 170px;"
                  hide-details
                  class="mr-0 mr-sm-6 mb-2"
                >
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date2"
                no-title
                color="primary"
                @input="menu2 = false"
                :locale="locale"
              >
              </v-date-picker>
            </v-menu>

            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$t('extras.end')"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                  style="width: 170px;"
                  hide-details
                  class="mr-0 mr-sm-6 mb-2"
                >
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                no-title
                @input="menu3 = false"
                color="primary"
              >
              </v-date-picker>
            </v-menu>
          <div>
            <v-btn
              color="primary"
              small
              outlined
              disabled
            >
              <span class="white--text text--disabled">
                {{ $t('instructions.filter') }}
              </span>
            </v-btn>
          </div>
          </v-col>
           
          <v-spacer class="d-none d-lg-flex"></v-spacer>
          <div
           class="d-flex flex-column flex-sm-row-reverse align-center pa-3"
          >
            <div>
              <v-btn
                color="primary"
                small
                outlined
                @click="exportPDF"
                class="mb-2 mb-sm-0"
              >
                <span class="white--text">
                  {{ $t('instructions.exportPdf') }}
                </span>
              </v-btn>
            </div>
            <div class="mr-0 mr-sm-4">
              <v-btn
                color="primary"
                small
                outlined
                class="mb-2 mb-sm-0"
                disabled
              >
                <span class="white--text text--disabled">
                  {{ $t('instructions.exportSpreadsheet') }}
                </span>
              </v-btn>
            </div>

          </div>
        </v-row>

          <!-- <div class="d-flex py-4 align-center">
            <div class="d-flex align-center">
              <v-btn
                color="primary"
                small
                outlined
                class="mr-6"
                @click="expandAll()"
              >
                <span class="white--text">
                  {{ $t('instructions.expandAll') }}
                </span>
              </v-btn>
    
              <v-btn
                color="primary"
                small
                outlined
                class="mr-8"
                @click="contractAll()"
              >
                <span class="white--text">
                  {{ $t('instructions.contractAll') }}
                </span>
              </v-btn>
    
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date2"
                    :label="$t('extras.start')"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    style="width: 170px;"
                    hide-details
                    class="mr-6"
                  >
                  </v-text-field>
                </template>
    
                <v-date-picker
                  v-model="date2"
                  no-title
                  color="primary"
                  @input="menu2 = false"
                  :locale="locale"
                >
                </v-date-picker>
              </v-menu>
    
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="$t('extras.end')"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    style="width: 170px;"
                    hide-details
                    class="mr-6"
                  >
                  </v-text-field>
                </template>
    
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu3 = false"
                  color="primary"
                >
                </v-date-picker>
              </v-menu>
    
              <v-btn
                color="primary"
                small
                outlined
                disabled
              >
                <span class="white--text text--disabled">
                  {{ $t('instructions.filter') }}
                </span>
              </v-btn>
            </div>
    
            <v-spacer></v-spacer>
    
            <div>
              <v-btn
                color="primary"
                small
                outlined
                class="mx-8"
                disabled
              >
                <span class="white--text text--disabled">
                  {{ $t('instructions.exportSpreadsheet') }}
                </span>
              </v-btn>
    
              <v-btn
                color="primary"
                small
                outlined
                @click="exportPDF"
              >
                <span class="white--text">
                  {{ $t('instructions.exportPdf') }}
                </span>
              </v-btn>
            </div>
          </div> -->
    
        <v-row class="d-flex">
          <v-col>
            <v-card
            class="py-4"
            outlined
            >
              <v-data-table
              class="elevation-0"
              :headers="headers"
              :items="reportsData"
              :items-per-page="5"
              item-key="rfq_id"
              :single-expand="false"
              :expanded.sync="expanded"
              show-expand
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 50]
              }"
              >
                <template #[`item.rfq_deal_time`]="{ item }">
                  <div class="d-flex flex-column">
                    <span>{{ item.rfq_deal_time | formatDate }}</span>
                  </div>
                </template>
                
                <template #[`item.rfq_amount`]="{ item }">
                  <v-chip
                  small
                  :text-color="item.rfq_side === 'BUY' ? 'alive' : 'orangeColor'"
                  :color="item.rfq_side === 'BUY' ? 'aliveTransp' : 'yellowTransp'"
                  >
                    <span class="font-weight-bold">
                      {{ item.rfq_amount | formatPrice }}
                    </span>
                 </v-chip>
                </template>
                
                <template #[`item.rfq_spotsett`]="{ item }">
                  <span>
                    {{ item.rfq_spotsett.toUpperCase() }}
                  </span>
                </template>

                <template #[`item.rfq_id`]="{ item }">
                  <span style="word-break: break-all; white-space: normal">
                    {{ item.rfq_id }}
                  </span>
                </template>
                
                <template v-slot:expanded-item="{ headers, item }">
                  <td 
                  :colspan="headers.length"
                  class="pa-8"
                  style="background: #121212"
                  >
                    <div class="text-h5 font-weight-light mb-5">
                      {{ $t('reports.expand.title') }}
                    </div>
      
                    <div class="mb-10">
                      <v-row no-gutters>
                        <!-- <v-col cols="3">
                          <span>
                            {{ $t('reports.expand.algorithm') }}: {{ item.rfq_lastquote.algo.algo_label }}
                          </span>
                        </v-col> -->
                        
                        <v-col cols="3" class="d-flex flex-column">
                          <span>
                            {{ $t('reports.expand.cfxIndex') }}: -
                          </span>
                          <span>
                            {{ $t('reports.expand.bestRate') }}: {{ item.best_rate }}
                          </span>
                          <span>
                            {{ $t('reports.expand.side') }}: 
                            <span
                            :class="{ 
                              'alive--text': item.rfq_side === 'BUY',
                              'orangeColor--text': item.rfq_side === 'SELL'
                            }"
                            >
                              {{ rfqSide(item.rfq_side) }}
                            </span>
                          </span>
                        </v-col>
                        
                        <v-col cols="2">
                        </v-col>
                        
                        <v-col cols="2">
                        </v-col>
                      </v-row>
                    </div>
      
                    <div class="text-h5 font-weight-light mb-5">
                      {{ $t('reports.expand.rates.title') }}
                    </div>

                    <v-card
                    elevation="0"
                    outlined
                    >
                      <v-simple-table
                 
                      style="background: #121212"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                {{ $t('quoteInfo.status') }}
                              </th>
                              <th class="text-left">
                                {{ $t('quoteInfo.bankName') }}
                              </th>
                              <th class="text-left">
                                {{ $t('quoteInfo.bankRate') }}
                              </th>
                              <th class="text-left">
                                {{ $t('quoteInfo.spreadAvg') }}
                              </th>
                              <th class="text-left">
                                {{ $t('quoteInfo.spreadPtax') }}
                              </th>
                              <th class="text-left">
                                {{ $t('quoteInfo.bankLastUpdate') }}
                              </th>
                              <!-- <th class="text-left">
                                {{ $t('quoteInfo.bankTimeDue') }}
                              </th>
                              <th class="text-left">
                                {{ $t('quoteInfo.id') }}
                              </th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                            v-for="rfq in item.rfq_lastquote.quote_list"
                            :key="rfq.quote_id"
                            >
                              <td>
                                <v-chip
                                  small
                                  :text-color="rfq.deal ? 'primary' : '#BDBDBD'"
                                  :color="rfq.deal ? 'primaryTransp' : '#212121'"
                                >
                                  <span class="font-weight-bold">
                                    {{ rfq.deal ? $t('reports.expand.rates.accepted').toUpperCase() : $t('reports.expand.rates.declined').toUpperCase() }}
                                  </span>
                                </v-chip>
                              </td>
                              <td>{{ rfq.bank_label }}</td>
                              <td>{{ rfq.quote_bank_rate }}</td>
                              <td>{{ rfq.spread_avg_spot }}</td>
                              <td>{{ rfq.spread_ptax }}</td>
                              <!-- 
                                TODO: try this
                                <td>{{ rfq.deal ? rfq.quote_bank_time : '-' | formatDate }}</td> 
                                -->
                              <td>{{ rfq.quote_bank_time ? $options.filters.formatDate(rfq.quote_bank_time) : '-' }}</td>
                                <!-- <td>{{ rfq.quote_bank_timedue | formatDate }}</td>
                                <td>{{ rfq.quote_id }}</td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
    </v-row>

    <v-dialog
      v-model="isGrid"
      persistent
      max-width="1500"
      overlay-opacity="0.59"
    >
      <v-card
        class="d-flex flex-column justify-space-between"
        elevation="10"
      >
        <v-img
          src="./../assets/new-clearfxailogo.png"
          aspect-ratio="16/9"
          max-height="50"
          class="mt-8"
          contain
        ></v-img>

        <v-card-title class="text-h6 justify-center font-weight-bold mb-5">
          {{ $t('reportDialog.title') }}
        </v-card-title>

        <v-card-text class="text-subtitle-1 text-center px-6">
          {{ $t('reportDialog.instruction.instructionOne') }}
          <br><br>
          {{ $t('reportDialog.instruction.instructionTwo') }}
          <br><br>
          {{ $t('reportDialog.instruction.instructionThree') }}
        </v-card-text>

        <v-card-text class="">
          <v-row class="d-flex justify-center" no-gutters>
            <v-col cols="6" class="d-flex flex-column">
              <span class="text-center text-h5 white--text mb-4">{{ $t('reportDialog.reportsView') }}</span>

              <v-img
                class="cursor-pointer"
                src="./../assets/report_view.png"
                max-height="250"
                contain
                @click="overlayOne = !overlayOne"
              ></v-img>

              <v-overlay
                :z-index="zIndex"
                :value="overlayOne"
              >
                <v-img
                class="cursor-pointer"
                src="./../assets/report_view.png"
                max-height="700"
                contain
                @click="overlayOne = false"
                ></v-img>
              </v-overlay>
              
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <span class="text-center text-h5 white--text mb-4">{{ $t('reportDialog.reportsPdf') }}</span>

              <v-img
                class="cursor-pointer"
                src="./../assets/report_pdf.png"
                max-height="250"
                contain
                @click="overlayTwo = !overlayTwo"
              ></v-img>

              <v-overlay
                :z-index="zIndex"
                :value="overlayTwo"
              >
                <v-img
                class="cursor-pointer"
                src="./../assets/report_pdf.png"
                max-height="700"
                contain
                @click="overlayTwo = false"
                ></v-img>
              </v-overlay>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex pb-6">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="redirectToFxMarket"
          >
            {{ $t('reportDialog.back') }}
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="redirectToProPurchase"
          >
            {{ $t('reportDialog.getGridPro') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as utils from "./../utils.js"
import { mapActions, mapState } from "pinia";
import { useAlgoRFQStore, useUtilsStore, useAuthStore } from "./../store/index";
import { exportPDF } from './../exportRfqsReportToPDF.js';

export default {
  filters: {
    formatDate(timestamp) {
    if (!timestamp || isNaN(timestamp)) {
      return "-"
    }
    return utils.getLocaleFormattedDateTime(timestamp);
  },
    formatPrice (price) {
      return utils.formatPrice(price)
    }
  },
  data () {
    return {
      items: [
      ],
      expanded: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      menu2: false,
      menu3: false,
      isGrid: false,
      overlayOne: false,
      overlayTwo: false,
      zIndex: 1,
      reports: []
    }
  },
  computed: {
    reportsData () {
      if (!this.reports) {
        return []
      }
      return this.reports.map(
        (element) => {
          const quoteList = element.rfq_lastquote.quote_list
          const quoteDealId = element.rfq_deal_quoteid
          for (let quote of quoteList) {
            // quote["bank_label"] = quote.bank_label
            quote["deal"] = quoteDealId === quote.quote_id
            // quote["spread_avg_spot"] = "-"
            // quote["spread_ptax"] = "-"
          }
          const bestRate = quoteList.reduce((prev, current) => {
            const prevRate = prev && !isNaN(prev.quote_bank_rate) ? parseFloat(prev.quote_bank_rate) : Infinity
            const currentRate = !isNaN(current.quote_bank_rate) ? parseFloat(current.quote_bank_rate) : Infinity

            return prevRate < currentRate ? prev : current
          }, undefined)
          element["best_rate"] = bestRate.quote_bank_rate
          element["rfq_lastquote"]["quote_list"] = quoteList
          return element
        }
      )
    },
    ...mapState(useUtilsStore, [
      "orangeColor",
      "yellowTransp",
      "aliveTransp"
    ]),
    ...mapActions(useAuthStore, [
      "getUserRoleFromLocalStorage"
    ]),
    now () {
      return utils.getLocaleFormattedDateTime()
    },
    headers() {
      return [
        { text: this.$t('quoteInfo.dealTime'), value: "rfq_deal_time", sortable: false },
        // { text: "Status", value: "rfq_deal", sortable: false },
        { text: this.$t('quoteInfo.ccy'), value: "rfq_ccy", sortable: false },
        // { text: "Side", value: "rfq_side", sortable: false },
        { text: this.$t('quoteInfo.amountCcy'), value: "rfq_amount", sortable: false },
        { text: this.$t('quoteInfo.settlement'), value: "rfq_spotsett", sortable: false },
        { text: this.$t('quoteInfo.id'), value: "rfq_id", sortable: false }
      ]
    },
    locale() {
      const savedLocale = localStorage.getItem('userLanguage');
      return savedLocale || 'en';
    }
  },
  mounted () {
    this.isGridpro()
    this.fetchReports()
  },
  methods: {
    ...mapActions(useAlgoRFQStore, [
      "orderList",
      "getReports"
    ]),
    fetchReports () {
      this.getReports()
        .then((response) => {
          const data = response.data
          this.reports = data.reports
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportPDF() {
      exportPDF(this.reportsData);
    },
    expandAll() {
      this.expanded = this.reportsData
    },
    contractAll() {
      this.expanded = []
    },
    rfqSide (rfq_side) {
      if (rfq_side === 'SELL') {
        return this.$i18n.t('side.sell').toUpperCase()
      }
      else {
        return this.$i18n.t('side.buy').toUpperCase()
      }
    },
    isGridpro () {
      const role = this.getUserRoleFromLocalStorage
      if (role === 'grid') {
        this.isGrid = true
      }
    },
    redirectToProPurchase () {
      const url = '#'
      window.open(url, "_blank")
    },
    redirectToFxMarket () {
      const url = 'fxmarket'
      this.$router.push({ path: url });
    }
  }
}
</script>

<style scoped>
</style>
