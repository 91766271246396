<template>
  <div 
    style="width: 100%; min-width: 270px; max-width: 270px;" 
    class="px-3"
  > <!-- style="max-width: 250px; min-width: 250px;" -->
    <!--
    <v-card
      class="mx-auto py-1 px-2 d-flex flex-column"
      max-width="250"
      min-width="250"
      outlined
      elevation="0"
      height="78"
      :color="'#000000'"
      style="border: thin solid rgba(255, 255, 255, 0.12);"
      @click="reveal = !reveal"
    >
    -->
      <div 
        class="d-flex align-center" 
        v-for="(event, index) in filteredEvents"
        :key="index"
      >
        <span 
          class="text-body-1 text--primary d-inline-block text-truncate mr-1"
          style="max-width: 180px; min-width: 180px;"
        >
          <v-icon
            large
            style="width: 12px; height: 12px;"
            :color="index === 0 ? 'primary' : index === 1 ? 'grey darken-1' : 'grey darken-4'"
          >
            mdi-circle-small
          </v-icon>
          {{ event.title }}
        </span>
        <!-- <span
          class="text-caption text--secondary d-inline-block text-truncate"
          style="max-width: 100px;"
        >
          {{ event.indicator }}
        </span> -->
        <v-spacer></v-spacer>
        <span 
          class="text-caption text--disabled d-inline-block text-truncate"
          style="max-width: 80px;"
        >
          {{ formatEventDate(event.date) }}
        </span>
      </div>
    <!--
    </v-card>
    -->
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia"
import { useAlgoRFQStore } from "./../store/index"

export default {
  data () {
    return {
      // reveal: false,
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(useAlgoRFQStore, [
      "getFXMarketEconomicCalendar",
    ]),
    convertedEvents() {
      if (!this.getFXMarketEconomicCalendar) return []
      return this.getFXMarketEconomicCalendar.map((event) => {
        let result = {}
        result["country"] = event.country

        let dateString = event.date.includes("T") ? event.date : `${event.date}T00:00:00Z`
        let date = new Date(dateString)
        let convertedDateEvent = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).getTime()
        result["date"] = convertedDateEvent
        result["indicator"] = event.indicator
        result["title"] = event.title
        return result
      })
    },
    sortedEvents() {
      return this.convertedEvents.slice().sort((a, b) => a.date - b.date)
    },
    filteredEvents() {
      const today = new Date().setHours(0, 0, 0, 0)
      const maxEvents = 3
      const maxDays = 10
      const foundEvents = []

      for (let dayOffset = 0; foundEvents.length < maxEvents && dayOffset < maxDays; dayOffset++) {
        const date = today + dayOffset * 86400000
        const event = this.findEventsForDate(date, this.sortedEvents)

        if (event) {
          foundEvents.push(event)
        }
      }

      return foundEvents
    },
  },
  methods: {
    ...mapActions(useAlgoRFQStore, [
    ]),
    getDate(days) {
      const date = new Date()
      date.setDate(date.getDate() + days)

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    findEventsForDate(date, filterData) {
      const nextDate = date + 86400000
      
      const eventBR = filterData.find(
        (event) => event.country === 'BR' && event.date >= date && event.date < nextDate
      )
      
      const eventUS = filterData.find(
        (event) => event.country === 'US' && event.date >= date && event.date < nextDate
      )
      
      const eventEU = filterData.find(
        (event) => event.country === 'EU' && event.date >= date && event.date < nextDate
      )

      return eventBR || eventUS || eventEU || null
    },
    formatEventDate(eventDate) {
      const today = new Date().setHours(0, 0, 0, 0)
      const eventTime = new Date(eventDate).setHours(0, 0, 0, 0)
      const diffInDays = (eventTime - today) / (1000 * 60 * 60 * 24)

      if (diffInDays === 0) return this.$i18n.t('economicCalendar.today')
      else if (diffInDays === 1) return this.$i18n.t('economicCalendar.tomorrow')
      else {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
          day: 'numeric',
          month: '2-digit'
        }).format(eventTime)
      }
    },
  }
}
</script>

<style scoped>
/* .v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 10
} */
</style>