import { defineStore } from "pinia"

export const APIKEY = process.env.VUE_APP_APIKEY
export const APIVERSION = process.env.VUE_APP_API_VERSION
export const API_PATH = `v${APIVERSION}/clearfxai`
export const APP_PROD = process.env.VUE_APP_PROD
export const CLEARFXAI_API_URL = process.env.VUE_APP_CLEARFXAI_API_URL
export const DEVSAFRA_API_URL = process.env.VUE_APP_DEVSAFRA_API_URL
export const SECRETKEY = process.env.VUE_APP_SECRETKEY
// export let STREAM_API_URL = process.env.VUE_APP_STREAM_API_URL

export let API = `api`
export let AUTH_PATH = `apiauth/${API_PATH}`
export let CONNECTIVITY_PATH = `apiconn`
export let SANDBOX_PATH = `apisandbox/v${APIVERSION}`
export let ALGO_RFQ_PATH = `${SANDBOX_PATH}/algorfq`

// if (process.env.NODE_ENV === "development") {
// }

export const useTableStore = defineStore("tableStore", {
  state: () => ({
    lastUpdate: undefined,
    di1: undefined,
    frc: undefined,
    pre: undefined,
    cpnDol: undefined,
    displayedCharts: {
      di1OrPre: null,
      frcOrcpnDol: null
    }
  }),
  getters: {
    getLastUpdate: (state) => state.lastUpdate,
    getdi1: (state) => state.di1,
    getfrc: (state) => state.frc,
    getpre: (state) => state.pre,
    getcpnDol: (state) => state.cpnDol,
  },
  actions: {
    loadDI1Data() {
      this.di1 = {
        name: "DI1",
        items: [
          { ticker: "DI1F25", values: [12.0217, 12.0267] },
          { ticker: "DI1H25", values: [10.2, 12.9675] },
          { ticker: "DI1J25", values: [10.2, 12.9675] },
          { ticker: "DI1N25", values: [11.2, 12.9675] },
          { ticker: "DI1U25", values: [11.2, 12.9675] }
        ]
      },
      this.updateLastUpdate()
    },
    loadFRCData() {
      this.frc = {
        name: "FRC",
        items: [
          { ticker: "FRCF25", values: [11.8, 12.1] },
          { ticker: "FRCH25", values: [10.5, 12.5] },
          { ticker: "FRCJ25", values: [10.3, 12.8] },
          { ticker: "FRCN25", values: [11.1, 12.6] },
          { ticker: "FRCU25", values: [11.3, 12.9] }
        ]
      },
      this.updateLastUpdate()
    },
    loadPREData() {
      this.pre = {
        name: "PRE",
        items: [
          { ticker: "PREF25", values: [13.0, 13.2] },
          { ticker: "PREH25", values: [12.7, 13.1] },
          { ticker: "PREJ25", values: [12.9, 13.3] },
          { ticker: "PREN25", values: [13.1, 13.4] },
          { ticker: "PREU25", values: [13.2, 13.5] }
        ]
      },
      this.updateLastUpdate()
    },
    loadCPNDolData() {
      this.cpnDol = {
        name: "CPN DOL",
        items: [
          { ticker: "CPNDOLF25", values: [4.8, 5.1] },
          { ticker: "CPNDOLH25", values: [4.7, 5.0] },
          { ticker: "CPNDOLJ25", values: [4.9, 5.2] },
          { ticker: "CPNDOLN25", values: [5.0, 5.3] },
          { ticker: "CPNDOLU25", values: [5.1, 5.4] }
        ]
      },
      this.updateLastUpdate()
    },
    setDisplayedCharts(di1OrPre, frcOrcpnDol) {
      this.displayedCharts = { di1OrPre, frcOrcpnDol }
    },
    updateLastUpdate() {
      this.lastUpdate = new Date().toISOString()
    }
    // downloadCSV(dataType) {
    //   const url = `${STREAM_API_URL}/${API}/intel/br/curve/${dataType}/data/csv`;
    //   return axios.get(url, {
    //     responseType: "arraybuffer",
    //   })
    // },
  }
})